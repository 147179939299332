var lethargy = new Lethargy(), isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
document.addEventListener("DOMContentLoaded", function() {


    //------------------------------//
    // APPELS AU PREMIER CHARGEMENT //
    //------------------------------//
    history.pushState({}, '', location);
    
    //requestion animation frame qui listen que les images soient chargées
    le_raf_chargement();

    if(!isMobile()){
        document.querySelector('body').classList.add('desktop');
    }else{
        document.querySelector('body').classList.add('mobile');
    }

    debut();

    // s'il y a des images à charger
    if(document.querySelectorAll('.preload').length !== 0){
        
        var imgsProchainePage = [];

        for (var i = 0; i < document.querySelectorAll('.preload').length; i++) {
            imgsProchainePage.push(document.querySelectorAll('.preload')[i].getAttribute('data-url'));
        }
        
        chargementImages(imgsProchainePage);

    }else{
        sortie_images = true;
    }

    raf();


    

    

    //swipe event
    document.addEventListener('touchstart', handleTouchStart, false);        
    document.addEventListener('touchmove', handleTouchMove, false);

    var xDown = null;                                                        
    var yDown = null;                                                        

    function handleTouchStart(evt) {                                         
        xDown = evt.touches[0].clientX;                                      
        yDown = evt.touches[0].clientY;                                      
    }                                                

    function handleTouchMove(evt) {
        if ( ! xDown || ! yDown ) {
            return;
        }

        var xUp = evt.touches[0].clientX;                                    
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        if( document.body.classList.contains('home') && !animEnCours){
            animEnCours = true;

            // Do something with the scroll event
            clearInterval(le_set_inter);
            laTransition();
            transition();
            gsap.killTweensOf('#progress > div');
            gsap.to(document.querySelector('#progress > div'), {
                duration: 1,
                scaleY:0,
                ease:Power2.in
            });
            laProgress();
            majNum();
        }

        // if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
        //     if( document.body.classList.contains('home') && bloque_action === false){
        //         if ( xDiff > 0 ) {
        //             next_slide();
        //         } else {
        //             prev_slide();
        //         }
        //     }                    
        // } else {
        //     if( document.querySelector('body').classList.contains('home') && bloque_action === false){
        //         if ( yDiff > 0 ) {
        //             next_slide();
        //         } else { 
        //             prev_slide();
        //         }
        //     }                                                               
        // }
        /* reset values */
        xDown = null;
        yDown = null;                                             
    }

    document.querySelector('.toMenu').addEventListener('click', function(){
        document.querySelector('.menuMob').style.display = "block";
        document.body.classList.add('menuOuvert');
        gsap.to('.menuMob', {
            duration:0.5,
            opacity:1
        });
    });

    document.querySelector('.toClose').addEventListener('click', function(){
        gsap.to('.menuMob', {
            duration:0.5,
            opacity:0,
            onComplete:function(){
                document.querySelector('.menuMob').style.display = "none";
            }
        });
    });
    
}); 


// on vérifie que l'anim de sortie est bonne, que la requete ajax est faite et que les images sont chargées
function le_raf_chargement() {

    raf_chargement = requestAnimationFrame(le_raf_chargement);
    
    if(sortie_ok && ajax_ok && sortie_images && sortie_intro){
        if(!premier_appel){
        	updatePage(contenu_nouvelle_page);
        }else{
            sortieIntro();
        }
        cancelAnimationFrame(raf_chargement);
    
    }
}






let sortie_ok = true, ajax_ok = true, lien_en_cours = false, raf_loader, raf_chargement, sortie_images = false, links, premier_appel = true, contenu_nouvelle_page, le_raf, ancien_height = 0, animEnCours = false, le_set_inter;
let sortie_intro = false;
let W = window.innerWidth, H = window.innerHeight;

let isToProjets = false, lastSlug;



// fonction appellée à chaque lancement de page
let initPage = function() {
	sortie_ok = false;
	ajax_ok = false;
    sortie_images = false;

    lien_en_cours = false;

   	// on lance les animations d'entree
    animationsEntree();

    // maj
    // quand clique on lien
    links = document.querySelectorAll('a');
    for(var a = 0; a < links.length; a++){
        links[a].removeEventListener('click', onClick);
        links[a].addEventListener('click', onClick);
    }

    function onClick (event) {
        
        if( !event.target.classList.contains('externe') ){

            if(event.target.classList.contains('toProjets')){
                isToProjets = true;
                lastSlug = event.target.getAttribute('data-slug');
            }

            if(event.target.classList.contains('resetSession') ){
                sessionStorage.setItem('cat', 'toAll');
                sessionStorage.removeItem('filtre');
                sessionStorage.setItem('scroll', 0);
            }
            if(event.target.classList.contains('toSingle')){
                if(document.querySelector('.toAll').classList.contains('current')){
                    sessionStorage.setItem('cat', 'toAll');
                }else if(document.querySelector('.toIndu').classList.contains('current')){
                    sessionStorage.setItem('cat', 'toIndu');
                }else {
                    sessionStorage.setItem('cat', 'toExpe');
                }
                sessionStorage.setItem('scroll',le_scroll.vars.current);

                if(document.querySelector('.innerFiltres .actif') != null){
                    sessionStorage.setItem('filtre',document.querySelector('.innerFiltres .actif').getAttribute('data-slug'));
                }
            }

            event.preventDefault();
            //temp
            // event.stopPropagation();

            // lancement requete ajax qu'une seule fois
            if( lien_en_cours === false ){

                // alert();

                lien_en_cours = true;
                let href = this.getAttribute('href');

                history.pushState({}, '', href);
                loadPage(href);
                le_raf_chargement();

                return false;

            }else{ return false; }
        }
    }
    

},

// quand get() terminé
ajaxLoad = function(html) {
    contenu_nouvelle_page = html;
    ajax_ok = true;

    // code pour charger les images dès que ajax terminé
    let parser = new DOMParser();
    let doc = parser.parseFromString(html, "text/html");
    
    if(doc.querySelectorAll('.preload').length !== 0){

        var imgsProchainePage = [];
        
        // doc.querySelectorAll('.preload').forEach(x => {
        //     imgsProchainePage.push(x.getAttribute('data-img'));
        // });
        for (var i = 0; i < doc.querySelectorAll('.preload').length; i++) {
            imgsProchainePage.push(doc.querySelectorAll('.preload')[i].getAttribute('data-url'));
        }

        //
        // console.log(doc.querySelectorAll('.preload')[0].getAttribute('data-url'));
        // let temp = new Image();
        // temp.src = doc.querySelectorAll('.preload')[0].getAttribute('data-url');
        // console.log(temp.client);

        chargementImages(imgsProchainePage);

    }else{
    	sortie_images = true;
    }

},
// animations de sortie sorties
loadPage = function(href) {

    let xhr = new XMLHttpRequest(),
        method = "GET",
        url = href;

    xhr.open(method, url, true);
    xhr.onreadystatechange = function () {
        if(xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            ajaxLoad(xhr.responseText);
        }
    };
    xhr.send(); 

    animationsSortie();

},
// mise à jour des données de la page
updatePage = function(html) {

    let parser = new DOMParser();
    let doc = parser.parseFromString(html, "text/html");
    let liste_class = doc.body.getAttribute('class');
    
    // maj titre de la page
    document.title = doc.querySelector('title').innerHTML;

    // maj class du body
    document.body.setAttribute('class', doc.body.getAttribute('class'));
    // maj lang html
    document.querySelector('html').setAttribute('lang', doc.querySelector('html').getAttribute('lang'));
    

    if(!isMobile()){
        document.body.classList.add('desktop');
    }else{
        document.body.classList.add('mobile');
    }

    // maj contenu #main
    // console.log(doc.getElementById('main').innerHTML);
    document.getElementById('main').innerHTML = doc.getElementById('main').innerHTML;

    // maj menu
    document.querySelector('header ul').innerHTML = doc.querySelector('header ul').innerHTML;

    //
    document.querySelector('header .logo1').setAttribute('href', doc.querySelector('header .logo1').getAttribute('href'));
    document.querySelector('header .logo2').setAttribute('href', doc.querySelector('header .logo2').getAttribute('href'));
    
    // on lance la nouvelle page
    initPage();
};




// gestion bouton prev/next navigateur
window.onpopstate = function(e) {
    
    if(e.state !== null) {

        loadPage(location.href);
        le_raf_chargement();
    }
};


function raf() {
    
    le_raf = requestAnimationFrame(raf);

    if(document.getElementById('main').clientHeight != ancien_height && !isMobile() && le_scroll !== null ){
        // console.log('changement height vs section');
        le_scroll.resize();
        ancien_height = document.getElementById('main').clientHeight;
    }


    // console.log(sortie_ok, ajax_ok, sortie_images);

}


let posX = 0, posY = 0;
document.addEventListener("mousemove", function(event){
    posX = event.pageX;
    posY = event.pageY;

});









// ENTREE
// ENTREE
let le_scroll = null;
function animationsEntree(){
    animEnCours = true;
    //console.log('par ici');
    

    if(document.querySelector('.intro') !== null){
        if(!document.body.classList.contains('page-template-contact')){
            document.querySelectorAll('.intro').forEach(x => {
                splitLines(x, '<span class="ligneIntro">', '</span>');
            });
        }else{
            document.querySelectorAll('.lintro').forEach(x => {
                splitLines(x, '<span class="ligneIntro">', '</span>');
            });
        }
        gsap.to('.ligneIntro', {duration:1, stagger:0.2, opacity:1, delay:0.4, y:0, ease:Power2.easeOut});
    }

    if(document.body.classList.contains('home')){
        eventsHome();
    }else if(document.body.classList.contains('archive')){
        eventsArchive();
    }else if(document.body.classList.contains('page-template-expertises')){
        eventsExper();
    }else if(document.body.classList.contains('single-projets')){

        traductions2();

        setTimeout(function(){
            var slider = document.querySelector('.main-carousel');
            if(slider !== null){ 
                let i = 0;
                document.querySelectorAll('.main-carousel').forEach(x => {
                    window['flick'+i] = new Flickity( x, {
                        cellAlign: 'center',
                        contain: true,
                        pageDots: true,
                        draggable: true,
                        wrapAround: true,
                        prevNextButtons: false,
                        on: {
                            ready: function() {
                            },
                            change: function( index ) { 
                                // updateStatus(index+1); 
                            }
                        }
                    });
                    i++;
                });
                // var flkty = new Flickity( slider, {
                //     cellAlign: 'center',
                //     contain: true,
                //     pageDots: true,
                //     draggable: true,
                //     wrapAround: true,
                //     prevNextButtons: false,
                //     on: {
                //         ready: function() {
                //             // var totalCells = this.cells.length;
                //             // var selectedCell = this.selectedIndex+1;
                //             // document.querySelector('.currentPro').innerHTML = '01';
                //             // document.querySelector('.numPro').innerHTML = '0'+this.cells.length;
                //         },
                //         // change: function( index ) { updateStatus(index+1); }
                //     }
                // });
            }
        }, 200);
        
        if(document.querySelector('.lecteurPlay') !== null){
            document.querySelector('.lecteurPlay').addEventListener('click', function(e){
                gsap.to('.lecteurPlay', {
                    opacity:0, duration:0.4,
                    onComplete:function(ee){
                        this._targets[0].style.display = "none";
                    }
                });
                e.target.parentNode.querySelector('video').play();
                e.target.parentNode.querySelector('video').controls = true;
            });
        }

        quotes();

        gsap.to('.flex.data > div', {duration:1, stagger:0.2, opacity:1, delay:0.4, y:0, ease:Power2.easeOut});
        gsap.to('.close', {duration:1.4, delay:1, y:0, ease:Power2.easeOut});
    }

    if(isToProjets){
        simulateClick(document.querySelector('.toExpe'));
        document.querySelectorAll('.filtre').forEach(x => {
            if(x.getAttribute('data-slug') === lastSlug){
                simulateClick(x);
            }
        });
        isToProjets = false;
    }

    window.scrollTo(0, 0);

    if(premier_appel){
        premier_appel = false;   
    }
    gsap.to('#main, .elmtEntree', 0.8, {
        opacity:1,
        ease:Power2.easeInOut,
        onComplete:function(){
            animEnCours = false;
        }
    });

    if(!isMobile() && document.querySelector('.vs-section') !== null){
        le_scroll = new global_custom2.default({
            preload: true,
            noscrollbar: true,
            native: false,
            section: document.querySelector('.vs-section'),
            divs: document.querySelectorAll('.vs-divs')
            //ease: 0.08

        });


        le_scroll.vs.options.mouseMultiplier = 0.4;

        le_scroll.resize();
        le_scroll.init();

        //le_scroll.vars.current = le_scroll.vars.target = sessionStorage.getItem('scroll');
        if(
            document.body.classList.contains('post-type-archive-projets') 
            //&& sessionStorage.getItem('filtre') != null
        ){
            le_scroll.scrollTo(sessionStorage.getItem('scroll'));
        }

        le_scroll.vs.on(function(){
            
        });
    }

    if(document.querySelector('.affiche') !== null && !document.body.classList.contains('home')){
        eventsScroll();
    }
    //     if(!isSafari){
    //         eventsScroll();
    //         eventsScroll2();
    //     }else{
    //         document.querySelectorAll('feDisplacementMap').forEach(x => {
    //             x.scale.baseVal = 0;
    //         });
    //     }
    // }

    document.querySelectorAll('.cta, .ctaspe').forEach(x => {
        x.addEventListener('mouseenter', function(){
            gsap.to(x.querySelector('span'), {
                opacity:0,
                y:'-10%',
                duration:0.2,
                ease:Power2.easeIn,
                onComplete:function(){
                    gsap.fromTo(x.querySelector('span'), {
                        y:'10%'
                    }, {
                        y:'0%',
                        opacity:1,
                        duration:0.2,
                        ease:Power2.easeOut
                    });
                }
            });
        });
    });

    


    // document.getElementById('submit').addEventListener('click', function(e){ 
    //     if(
    //         document.getElementById('name').checkValidity() &&
    //         document.getElementById('mail').checkValidity() &&
    //         document.getElementById('phone').checkValidity() &&
    //         document.getElementById('company').checkValidity() &&
    //         document.getElementById('message').checkValidity()
    //     ){
    //         e.preventDefault();
    //         submitForm();
    //     }
    // });


}




// SORTIE
// SORTIE
function animationsSortie(){

    document.body.classList.add('progress');

    //home
    if(document.body.classList.contains('home')){
        clearInterval(le_set_inter);
        gsap.killTweensOf('#progress > div');
    }

    if(document.body.classList.contains('menuOuvert')){
        simulateClick(document.querySelector('.toClose'));
        gsap.set('#main', {opacity:0});
    }

    cancelAnimationFrame(le_raf_scroll);
    cancelAnimationFrame(le_raf_ligne);

    animEnCours = false;
    gsap.to('#main', 0.8, {opacity:0, ease:Power2.easeInOut, onComplete:function(){
        finSortie();
    }});
    
}

function finSortie(){
    if(!isMobile() && document.querySelector('.vs-section') !== null){
        le_scroll.vars.current = le_scroll.vars.target = 0;
        if(le_scroll !== null){le_scroll.destroy();}
        le_scroll = null;
    }
    sortie_ok = true;
}






// EVENTS HOME
// EVENTS HOME
// EVENTS HOME
// EVENTS HOME
let currentImg = 0, delayed = 0, nb_pass = 0, duration = 6000;
function eventsHome(){
    laProgress();
    // majNum();
    transition();

    document.getElementById('discover').setAttribute('href', document.querySelector('#img1 .cover.current').getAttribute('data-perma'));
    document.getElementById('img1').setAttribute('href', document.querySelector('#img1 .cover.current').getAttribute('data-perma'));
    document.getElementById('img2').setAttribute('href', document.querySelector('#img1 .cover.current').getAttribute('data-perma'));
    document.getElementById('img3').setAttribute('href', document.querySelector('#img1 .cover.current').getAttribute('data-perma'));

    if(!isMobile()){
        document.querySelectorAll('#discover, #img1, #img2, #img3').forEach(x => {
            x.addEventListener('mouseenter', function(){
                document.querySelectorAll('.distort').forEach(x => {
                    x.classList.add('zoom');
                });
            });
            x.addEventListener('mouseleave', function(){
                document.querySelectorAll('.distort').forEach(x => {
                    x.classList.remove('zoom');
                });
            });
        })
    }

    document.addEventListener('wheel', scrollEvent);
    document.addEventListener('mousewheel', scrollEvent);
    document.addEventListener('DOMMouseScroll', scrollEvent);

}

function laProgress(){
    gsap.to(document.querySelector('#progress > div'), {
        delay:1,
        duration: duration/1000 - 1,
        scaleY:1,
        ease:Power1.easeOut,
        onComplete:function(){
            if(document.body.classList.contains('home')){
                document.getElementById('progress').classList.add('enBas');
                gsap.to(document.querySelector('#progress > div'), {
                    duration: 1,
                    scaleY:0,
                    onComplete:function(){
                        if(document.getElementById('progress') !== null){
                            document.getElementById('progress').classList.remove('enBas');
                        }
                    }
                });
            }
        }
    });
}

       
function eventsExper(){
    if (document.querySelector('html').getAttribute('lang') == 'fr-FR') {
        document.querySelectorAll('.wording').forEach(x => {
            if(x.innerHTML == "Digital"){
                x.innerHTML = "Digitaux";
            }else if(x.innerHTML == "Strategy"){
                x.innerHTML = "Stratégie";
            }else if(x.innerHTML == "Videos"){
                x.innerHTML = "Vidéos";
            }
        });

        document.querySelectorAll('.mob').forEach(x => {
            x.innerHTML = "Projets";
        });
    }
}


let progress = {};
let incr = 0;
progress.aller = 0;
progress.retour = 100;
function transition(){
    le_set_inter = setInterval(function(){
        animEnCours = true;
        laTransition();
        laProgress();
        majNum();
        
    }, duration);
}
function majNum(){
    
    gsap.to('.num span', {
        duration:0.5,
        delay:1,
        y:'-100%',
        ease:Power2.easeIn,
        stagger:0.1,
        onComplete:function(){
            document.querySelector('.digi2').innerHTML = indexInParent(document.querySelector('.cover.current')) +1;
            
            gsap.fromTo('.num span', {
                y:'100%'
            },{
                duration:0.5,
                y:'0%',
                ease:Power2.easeOut,
                stagger:0.1
            });

            animEnCours = false;
        }
    });
    
}

function laTransition(){
    progress.aller = 0;
    progress.retour = 100;

    document.querySelectorAll('feDisplacementMap').forEach(x => {
        x.scale.baseVal = 0;
    });

    document.querySelectorAll('.innerImgs').forEach(x => {

        // depart
        gsap.to(x.querySelector('.current'), {
            duration:1,
            y:"-100%",
            delay: delayed,
            ease:Power2.easeInOut
        });
        gsap.to(x.querySelector('.current').querySelector('div'), {
            duration:1,
            scale:1.3, // avant 1.0
            y:"90%",
            delay: delayed,
            ease:Power2.easeInOut
        });

        
        gsap.to(progress, {
            duration:1,
            aller:100,
            delay: delayed,
            ease:Power2.easeInOut,
            onUpdate: function(){
                x.querySelector('.current').querySelector('feDisplacementMap').scale.baseVal = progress.aller;
            }
        });

        let elmt;
        if(x.querySelector('.current').nextElementSibling !== null){
            elmt = x.querySelector('.current').nextElementSibling;
        }else{
            elmt = x.querySelectorAll('.cover')[0];
        }

        // arrivee
        gsap.fromTo(elmt, {
                y:"100%"
            },{
            duration:1,
            y:"0%", 
            delay: delayed,
            ease:Power2.easeInOut,
            onComplete:function(){ 
                x.querySelector('.current').classList.remove('current');
                elmt.classList.add('current');

                if(x.querySelector('.current').getAttribute('data-perma') !== null){
                    document.getElementById('discover').setAttribute('href', x.querySelector('.current').getAttribute('data-perma'));
                    document.getElementById('img1').setAttribute('href', x.querySelector('.current').getAttribute('data-perma'));
                    document.getElementById('img2').setAttribute('href', x.querySelector('.current').getAttribute('data-perma'));
                    document.getElementById('img3').setAttribute('href', x.querySelector('.current').getAttribute('data-perma'));
                }


            }
        });
        gsap.fromTo(elmt.querySelector('div'), {
                y:"-90%",
                scale:1.2
            },{
            duration:1,
            y:"0%",
            scale:1.1,
            delay: delayed,
            ease:Power2.easeInOut
        });

        gsap.to(progress, {
            duration:1,
            retour:0,
            delay: delayed,
            ease:Power2.easeInOut,
            onUpdate: function(){
                elmt.querySelector('feDisplacementMap').scale.baseVal = progress.retour;
            }
        });

        delayed += 0.15;
        nb_pass ++;
        if(nb_pass === 3){
            nb_pass = 0;
            delayed = 0;
        }

        
        
    });
    gsap.to('h2.current', {
        duration:0.5,
        delay:0.8,
        y:'-10%',
        opacity:0,
        ease:Power2.easeIn,
        onComplete:function(){
            let elmt;
            if(document.querySelector('h2.current').nextElementSibling !== null){
                elmt = document.querySelector('h2.current').nextElementSibling;
            }else{
                elmt = document.querySelectorAll('#img1 h2')[0];
            }
            document.querySelector('h2.current').classList.remove('current');
            elmt.classList.add('current');
            document.querySelectorAll('#img1 h2').forEach(x => {
                x.style.opacity = '0';
            });

            gsap.fromTo('h2.current', {
                y:'10%'
            },{
                duration:0.5,
                ease:Power2.easeOut,
                opacity:1,
                y:'0%'
            });
        }
    });
}







// EVENTS ARCHIVE
// EVENTS ARCHIVE
// EVENTS ARCHIVE
// EVENTS ARCHIVE
function eventsArchive(){

    document.querySelector('.toAll').addEventListener('click', function(e){
        gsap.to('.float', {duration:0.5, opacity:0, onComplete:function(){
            montrerAll();
            changeCurrent();
            e.target.classList.add('current');
            document.querySelector('.fIndu').style.display = document.querySelector('.fExpe').style.display = "none";
            majAffichage();

            gsap.to('.float', {duration:0.5, opacity:1});
        }});
    });

    document.querySelector('.toIndu').addEventListener('click', function(e){
        gsap.to('.float', {duration:0.5, opacity:0, onComplete:function(){
            cacherAll();
            changeCurrent();
            e.target.classList.add('current');

            document.querySelector('.fIndu').style.display = "block";
            document.querySelector('.fExpe').style.display = "none";

            document.querySelectorAll('.proj').forEach( x => {
                if(x.getAttribute('data-indu') !== ''){
                    x.classList.add('affiche');
                }
            });
            reinitSousCat();
            majAffichage();

            gsap.to('.float', {duration:0.5, opacity:1});
        }});
    });

    document.querySelector('.toExpe').addEventListener('click', function(e){
        gsap.to('.float', {duration:0.5, opacity:0, onComplete:function(){
            cacherAll();
            changeCurrent();
            e.target.classList.add('current');

            document.querySelector('.fIndu').style.display = "none";
            document.querySelector('.fExpe').style.display = "block";

            document.querySelectorAll('.proj').forEach( x => {
                if(x.getAttribute('data-expe') !== ''){
                    x.classList.add('affiche');
                }
            });

            reinitSousCat();
            majAffichage();

            gsap.to('.float', {duration:0.5, opacity:1});
        }});
    });

    // filtre sous cat
    document.querySelectorAll('.filtre').forEach(x => {
        x.addEventListener('click', function(e){

            sessionStorage.setItem('filtre',e.target.getAttribute('data-slug'));

            gsap.to('.float', {duration:0.5, opacity:0, onComplete:function(){

                document.querySelectorAll('.filtre').forEach(y => {
                    if(y !== x && y.classList.contains('actif')){
                        y.classList.remove('actif');
                    }
                });
                let slug = x.getAttribute('data-slug');

                cacherAll();

                if(e.target.classList.contains('industry')){
                    e.target.classList.toggle('actif');
                    if(!e.target.classList.contains('actif')){
                        document.querySelectorAll('.proj').forEach( x => {
                            if(x.getAttribute('data-indu') !== ''){
                                x.classList.add('affiche');
                            }
                        });
                    }else{
                        document.querySelectorAll('.proj').forEach( x => {
                            let list = x.getAttribute('data-indu');
                            let tabl = list.split(";");
                            let el = x;
                            tabl.shift();

                            tabl.forEach(x => {
                                if(x === slug){
                                    el.classList.add('affiche');
                                }
                            });
                        });
                    }
                }

                if(e.target.classList.contains('expertises')){
                    e.target.classList.toggle('actif');
                    if(!e.target.classList.contains('actif')){
                        document.querySelectorAll('.proj').forEach( x => {
                            if(x.getAttribute('data-expe') !== ''){
                                x.classList.add('affiche');
                            }
                        });
                    }else{
                        document.querySelectorAll('.proj').forEach( x => {
                            let list = x.getAttribute('data-expe');
                            let tabl = list.split(";");
                            let el = x;
                            tabl.shift();

                            tabl.forEach(x => {
                                if(x === slug){
                                    el.classList.add('affiche');
                                }
                            });
                        });
                    }
                }

                // fonction colorier toutes les sous cats si aucune de select
                document.querySelector('.innerFiltres').classList.add('debut');
                document.querySelectorAll('.innerFiltres > div').forEach(x => {
                    let contientActif = false;
                    x.querySelectorAll('.filtre').forEach(y => {
                        // console.log(y.classList)
                        if(y.classList.contains('actif')){
                            contientActif = true;
                        }
                    });
                    
                    if(contientActif){
                        document.querySelector('.innerFiltres').classList.remove('debut');
                    }
                    // else{
                    //     document.querySelector('.innerFiltres').classList.add('debut');
                    // }
                });

                majAffichage();

                //ICI
                gsap.to('.float', {duration:0.5, opacity:1});



            }});
        });
    });

    majAffichage();

    if (document.querySelector('html').getAttribute('lang') == 'fr-FR') {
        traductions();
    }


    // EVOLUTION
    // EVOLUTION
    if(sessionStorage.getItem('filtre') != null){
        let parent = document.querySelector('div[data-slug="'+sessionStorage.getItem('filtre')+'"]').parentNode;
    }
        // if(parent.classList.contains('fIndu')){
        //     document.querySelector('.toIndu').classList.add('current');
        //     simulateClick(document.querySelector('.toIndu'));
        // }else{
        //     document.querySelector('.toExpe').classList.add('current');
        //     simulateClick(document.querySelector('.toExpe'));
        // }

    if(sessionStorage.getItem('cat') == 'toAll'){
        document.querySelector('.toAll').classList.add('current');
        simulateClick(document.querySelector('.toAll'));

    }else if(sessionStorage.getItem('cat') == 'toIndu'){
        document.querySelector('.toIndu').classList.add('current');
        simulateClick(document.querySelector('.toIndu'));

    }else{
        document.querySelector('.toExpe').classList.add('current');
        simulateClick(document.querySelector('.toExpe'));
    }
    
    if(sessionStorage.getItem('filtre') != null){  
        simulateClick(document.querySelector('div[data-slug="'+sessionStorage.getItem('filtre')+'"]'));
    }
    //}
    
}

function traductions(){
    if(document.querySelector('.toIndu span') != null){
        document.querySelector('.toIndu span').innerHTML = "Industries";
    }
    
    document.querySelectorAll('.fIndu .filtre, .fInd .filtr').forEach(x => {
        if(x.getAttribute('data-slug') == 'beauty-fragrances'){
            x.innerHTML = "Beauté & Parfums";
        }else if(x.getAttribute('data-slug') == 'fashion'){
            x.innerHTML = "Mode";
        }else if(x.getAttribute('data-slug') == 'food-beverages'){
            x.innerHTML = "Grande consommation";
        }else if(x.getAttribute('data-slug') == 'hotel-tourism'){
            x.innerHTML = "Hôtellerie & tourisme";
        }else if(x.getAttribute('data-slug') == 'jewelry-watches'){
            x.innerHTML = "Joaillerie & Montres";
        }else if(x.getAttribute('data-slug') == 'real-estate'){
            x.innerHTML = "Immobilier";
        }else if(x.getAttribute('data-slug') == 'wines-spirits'){
            x.innerHTML = "Vins & Spiritueux";
        }   
    });

    document.querySelectorAll('.fExpe .filtre, .fExp .filtr').forEach(x => {
        if(x.getAttribute('data-slug') == 'advertising'){
            x.innerHTML = "Publicité";
        }else if(x.getAttribute('data-slug') == 'photography-films'){
            x.innerHTML = "Photographie";
        }else if(x.getAttribute('data-slug') == 'product-design'){
            x.innerHTML = "Design produit";
        }else if(x.getAttribute('data-slug') == 'strategy'){
            x.innerHTML = "Stratégie";
        }else if(x.getAttribute('data-slug') == 'videos'){
            x.innerHTML = "Films & Vidéos";
        }   
    });
}

function traductions2(){
    if (document.querySelector('html').getAttribute('lang') == 'fr-FR') {
        if(document.querySelector('.nomIndu') != null){
            document.querySelector('.nomIndu').innerHTML = "Industries";
        }
        if(document.querySelector('.nomCred') != null){
            document.querySelector('.nomCred').innerHTML = "Crédits";
        }

        traductions();
    }
}

function cacherAll(){
    document.querySelectorAll('.proj').forEach( x => {
        // x.style.display = 'none';
        x.classList.remove('affiche', 'bloc1', 'bloc2', 'bloc3', 'bloc4', 'bloc5', 'bloc6', 'bloc7');
    });
}
function montrerAll(){
    document.querySelectorAll('.proj').forEach( x => {
        // x.style.display = 'block';
        x.classList.add('affiche');
    });
}
function changeCurrent(){
    document.querySelectorAll('.cats > div').forEach( x => {
        x.classList.remove('current');
    });
    sessionStorage.removeItem('filtre');
}
function reinitSousCat(){
    document.querySelector('.innerFiltres').classList.add('debut');
    document.querySelectorAll('.filtre.actif').forEach(x => {
        x.classList.remove('actif');
    });
}

function majAffichage(){
    let i = 1;
    document.querySelectorAll('.proj.affiche').forEach(x => {
        x.classList.add('bloc'+i);
        i++;
        if(i === 8){
            i = 1;
        }
    });

    document.querySelectorAll('.float .actif').forEach(z => {
        z.classList.remove('actif');
        // z.querySelector('feDisplacementMap').scale.baseVal = 80;
    });
}





//
document.addEventListener("visibilitychange", function() {
    if (document.hidden){
        if(document.body.classList.contains('home')){
            clearInterval(le_set_inter);
        }
    } else {
        if(document.body.classList.contains('home')){
            transition();
        }
    }
});







function debut(){
    gsap.to('.nomLong, .creative', {
        y:"0%",
        duration:1,
        delay:0.2,
        opacity:1,
        ease:Power2.easeOut,
        stagger:0.2
    });

    gsap.to('#intro .nom', {
        y:"-20%",
        duration:0.5,
        delay:3,
        opacity:0,
        ease:Power2.easeIn,
        onComplete:function(){
            sortie_intro = true;
        }
    });

    // splitLines(document.querySelector('#intro .phrase'),'<span>','</span>');
    // document.querySelector('#intro .phrase').classList.add('ok');

}

function splitLines(container, opentag, closingtag) {
    var spans = container.children,
        top = 0,
        tmp = '';

    container.innerHTML = container.textContent.replace(/\S+/g, '<n>$&</n>');      
    for (let i = 0; i < spans.length; i++) {
        var rect = spans[i].getBoundingClientRect().top;
        if (top < rect){
            tmp += closingtag + opentag;
        }
        top = rect;
        tmp += spans[i].textContent + ' ';
    }
    container.innerHTML = tmp += closingtag;
}






function sortieIntro(){

    // gsap.to(document.querySelectorAll('#intro .phrase span'), {
    //     opacity:1,
    //     y:"0%",
    //     // delay:0.5,
    //     duration:1,
    //     ease:Power2.easeOut,
    //     stagger:0.1, 
    //     onComplete:function(){
    //         document.getElementById('intro').classList.add('trans');
    //     }
    // });
    // document.getElementById('intro').classList.add('trans');
    document.getElementById('main').style.opacity = "1";
    // gsap.to('#intro')

    // gsap.to('#intro', {
    //     opacity:0,
    //     // delay:3.5,
    //     duration:2,
    //     onComplete:function(){
    //         document.getElementById('intro').style.display = "none";

    //         gsap.to('header, .elmtEntree', {
    //             y:'0%',
    //             duration:2,
    //             opacity:1,
    //             ease:Power2.easeOut
    //         });

    //         if(document.body.classList.contains('home')){
    //             initPage();  
    //         } 
    //     }
    // });

    gsap.set('#intro', {opacity:0});

    gsap.to('header, .elmtEntree', {
        y:'0%',
        duration:3,
        opacity:1,
        delay:1,
        ease:Power2.easeOut
    });

    setTimeout(function(){
        document.getElementById('intro').style.display = "none";
    }, 2000);

    // if(!document.body.classList.contains('home')){
    initPage();
    // }
}



let le_raf_scroll;
function eventsScroll() {
    le_raf_scroll = requestAnimationFrame(eventsScroll);
    document.querySelectorAll('.affiche').forEach(x => {
        if(!x.classList.contains('actif') && x.getBoundingClientRect().top < window.innerHeight){
            x.classList.add('actif');
        }
    });
}




function quotes(){
    document.querySelectorAll('.quote').forEach(x => {
        splitLines(x, '<span class="ligne">', '</span>');
    });
    rafLignes();
}
let le_raf_ligne;
function rafLignes(){
    le_raf_ligne = requestAnimationFrame(rafLignes);
    document.querySelectorAll('.ligne').forEach(x => {
        if(x.getBoundingClientRect().top < 0.8 * window.innerHeight && !x.classList.contains('actif')){
            x.classList.add('actif');
            gsap.to(x, {
                duration:1,
                y:'0%',
                opacity:1,
                ease:Power2.easeOut
            });
        }
    });
}



function scrollEvent(e) {

    if(lethargy.check(e) !== false && document.body.classList.contains('home') && !animEnCours){
        animEnCours = true;

        // Do something with the scroll event
        clearInterval(le_set_inter);
        laTransition();
        transition();
        gsap.killTweensOf('#progress > div');
        gsap.to(document.querySelector('#progress > div'), {
            duration: 1,
            scaleY:0,
            ease:Power2.in
        });
        laProgress();
        majNum();
    }
};



if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}


// if (!String.prototype.includes) {
//   String.prototype.includes = function(search, start) {
//     'use strict';

//     if (search instanceof RegExp) {
//       throw TypeError('first argument must not be a RegExp');
//     } 
//     if (start === undefined) { start = 0; }
//     return this.indexOf(search, start) !== -1;
//   };
// }


if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    value: function (searchElement, fromIndex) {

      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If len is 0, return false.
      if (len === 0) {
        return false;
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      var n = fromIndex | 0;

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x, y) {
        return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
      }

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(searchElement, elementK) is true, return true.
        // c. Increase k by 1.
        if (sameValueZero(o[k], searchElement)) {
          return true;
        }
        k++;
      }

      // 8. Return false
      return false;
    }
  });
}
